<template>
  <el-card>
    <el-row>
      <el-date-picker
        class="vg_ml_8 vg_mr_8 topDateRangeSearch"
        size="small"
        v-model="dateRange"
        type="daterange"
        range-separator="至"
        start-placeholder="录入开始日期"
        end-placeholder="录入结束日期"
      />
      <el-button style="margin-bottom: 10px" type="info" size="small" class="vd_export" @click="buttonRefresh()"> 刷新</el-button>
      <el-button style="margin-bottom: 10px" type="primary" size="small" class="vd_export" @click="getFequPushList()"> 查询</el-button>
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="vg_cursor">
          <el-table-column type="index" label="序号" align="center" width="50px" />
          <el-table-column label="退回时间" prop="fequ_push_time" width="150px" :formatter="formatDate" />
          <el-table-column label="工厂打样单号" width="150px" prop="fequ_no" />
          <el-table-column label="委托打样单号" width="150px" prop="requ_no" />
          <el-table-column label="货号" width="200px" prop="back_prod_no_and_suffix" />
          <el-table-column label="退回原因" prop="fequ_push_text" width="auto">
            <template v-slot="scope">
              <el-tooltip popper-class="item" effect="dark" placement="top">
                <div slot="content" class="item">
                  {{ scope.row.fequ_push_text }}
                </div>
                <span
                  style="
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    white-space: pre-line;
                  "
                  >{{ scope.row.fequ_push_text }}</span
                >
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="123px">
            <template v-slot="scope">
              <div style="display: flex">
                <el-button type="success" size="small" @click="toFequEdit(scope.row)">查看</el-button>
                <el-button type="danger" size="small" @click="deleteFequPush(scope.$index, scope.row)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { get, post } from '@api/request';
import { fequAPI } from '@api/modules/fequ';
import bus from '@/components/common/bus';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'fequPush9003',
  data() {
    return {
      tableData: [],
      loading: true,
      dateRange: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getFequPushList();
    },
    getFequPushList() {
      this.loading = true;
      let [startTime, endTime] = this.dateRange || [];
      let start_time = startTime?.getTime();
      let end_time = endTime?.getTime();
      get(fequAPI.fequPush9003List, { stff_id: this.$cookies.get('userInfo').stff_id, start_time: start_time, end_time: end_time })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            setTimeout(() => {
              this.loading = false;
            }, 200);
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //刷新
    buttonRefresh() {
      this.dateRange = [];
      this.initData();
    },
    //跳转工厂打样单
    toFequEdit(row) {
      this.jump('/fequ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 116,
            form_id: row.fequ_id
          })
        )
      });
      if (row.fequ_push_text === '整单退回。') {
        post(fequAPI.deleteFequPush9003, { fequ_id: row.fequ_id })
          .then(res => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg);
              this.initData();
              return;
            }
            this.$message.error(res.data.msg);
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    //跳转委托打样单
    toRequEdit(row) {
      this.jump('/requ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 114,
            form_id: row.requ_id
          })
        )
      });
      // post(fequAPI.deleteFequPush9003, { fequpush_id: row.fequpush_id })
      //   .then(res => {
      //     if (res.data.code === 0) {
      //       this.$message.success(res.data.msg);
      //       this.initData();
      //       return;
      //     }
      //     this.$message.error(res.data.msg);
      //   })
      //   .catch(res => {
      //     this.$message.error(res.data.msg);
      //   });
    },
    //删除
    deleteFequPush(index, row) {
      this.$confirm('确认完成?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(fequAPI.deleteFequPush9003, { fequpush_id: row.fequpush_id })
            .then(res => {
              if (res.data.code === 0) {
                //刷新首页推送提醒数量
                bus.$emit('getPermInfo');
                this.$message.success(res.data.msg);
                this.initData();
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 时间转换
    formatDate(row) {
      if (row.fequ_push_time) {
        return this.helper.toTime13(row.fequ_push_time);
      } else {
        return '暂无';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.item {
  max-width: 1500px !important; /* 根据需要设置 */
}

::v-deep .el-tooltip__popper {
  max-width: 1500px;
}
</style>
